import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//Images

const titleBlog = [
  {
    country: 'India',
    title1: 'India Careers',
    title2: 'Jobs in India subcontinent',
  },
  // { title1: 'PHP Mobile', title2: 'Web Services' },
  // { title1: 'PHP Backend', title2: 'Solutions' },
  // { title1: 'Social Networking', title2: 'Development' },
  // { title1: 'PHP eCommerce', title2: 'Solution' },
  // { title1: 'PHP', title2: 'Frameworks' },
];

class Careers extends Component {
  render() {
    return (
      <>
        <div className='page-content bg-white'>
          <div style={{ height: '100px' }}></div>
          <div className='content-block'>
            {/* <!-- About Us --> */}
            {/* <div className='section-full content-inner exp-services'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='section-head text-black text-center'>
                      <h4 className='text-gray-dark font-weight-500 m-b10'>
                        Career
                      </h4>
                      <h2 className='box-title m-tb0'>
                        Scalable, interactive & custom PHP solutions on your way
                        <span className='bg-primary'></span>
                      </h2>
                      <p className='font-weight-300 font-18'>
                        Within a span of{' '}
                        <strong className='text-primary'>15</strong> years Team
                        In India has emerged as one of the leading companies
                        teaming the expert and experienced PHP developers. With
                        the undaunted skills and the unmatched assistance our
                        team of <strong className='text-yellow'>300+</strong>{' '}
                        strong and dedicated PHP developers have developed{' '}
                        <strong className='text-red'>2500+</strong> portals and
                        websites for{' '}
                        <strong className='text-pink'>2000+</strong> clients. We
                        support clients from all over the world including USA,
                        UK, Australia, France, and New Zealand.
                      </p>
                    </div>
                    <div className='row serv'>
                      {iconWraper.map((data, index) => (
                        <div
                          className='col-lg-2 col-md-4 col-sm-6 m-b30'
                          key={index}
                        >
                          <div className='icon-bx-wraper expertise  bx-style-1 p-lr10 p-tb20 center'>
                            <div className='icon-bx-sm bg-primary radius m-b20'>
                              <Link to={'#'} className='icon-cell'>
                                {data.icon}
                              </Link>
                            </div>
                            <div className='icon-content'>
                              <h5 className='dlab-tilte'>
                                <Link to={'#'}>{data.title}</Link>
                              </h5>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className='section-full'>
              <div className='container-fluid'>
                <div className='row dzseth'>
                  <div className='col-lg-12 col-md-12 p-a0 img-fix bg-black-light p-lr0 dis-tbl'>
                    <div className='p-a30 dis-tbl-cell '>
                      <div className='max-w700 ml-5 p-tb0'>
                        <div className='row'>
                          <div className='col-lg-12 text-white'>
                            <h3 className='box-title m-b10'>
                              CAREERS<span className='bg-primary'></span>
                            </h3>
                            <h5 className='m-b30'>
                              <p>Explore your passion.</p>
                              <p>Make an overwhelming impact.</p> Work with
                              us—and transform your career while transforming
                              lives.
                            </h5>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-12 m-b30 chosesus-content'>
                            <Link to={'#'}>
                              <button className='btn btn-outline-info white'>
                                Read More
                                <i
                                  className='fa fa-long-arrow-right'
                                  style={{
                                    marginLeft: '50px',
                                  }}
                                ></i>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-lg-6 col-md-12 '>
                    <img src={about} alt='' className='img-cover' />
                  </div> */}
                </div>
              </div>
            </div>

            <div className='section-full content-inner'>
              <div className='container'>
                <div className='section-head text-black text-center'>
                  <h2 className='box-title m-tb0'>
                    Discover Job Opportunities{' '}
                    <span className='bg-primary'></span>
                  </h2>
                </div>
              </div>
              <div className='container'>
                <div className='row '>
                  {titleBlog.map((data, index) => (
                    <div
                      className='col-lg-4 col-md-6 col-sm-6 m-b30'
                      key={index}
                    >
                      <div className='icon-bx-wraper expertise  bx-style-1 p-a30 center'>
                        <div className='icon-content'>
                          <h4 className='dlab-tilte'>
                            <Link to={'#'}>
                              {data.title1} <br />
                            </Link>
                          </h4>
                          <p> {data.title2}</p>
                          <Link
                            to={`/jobs/${data.country}`}
                            className='btn btn-outline-info'
                          >
                            Explore
                            <i
                              class='bi bi-arrow-up-right-square'
                              style={{
                                marginLeft: '20px',
                              }}
                            ></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* <SectionCounter />

            <div
              className='section-full content-inner'
              style={{
                backgroundImage: 'url(' + bgmap + ')',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className='container'>
                <div className='section-head text-center'>
                  <h2 className='box-title m-tb0'>
                    Our Testimonials<span className='bg-primary'></span>
                  </h2>
                  <p>
                    {' '}
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the{' '}
                  </p>
                </div>
                <div className='section-content m-b30 '>
                  <TestimonialCarousel />
                </div>
              </div>
            </div>

            <div
              className='section-full overlay-primary-dark bg-img-fix'
              style={{ backgroundImage: 'url(' + bg1 + ')' }}
            >
              <FormStyle />
            </div> */}
          </div>
        </div>
      </>
    );
  }
}
export default Careers;

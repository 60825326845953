import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer1 extends Component {
  render() {
    return (
      <>
        <footer className='site-footer text-uppercase'>
          <div className='footer-top'>
            <div className='container'>
              <div className='row'>
                <div className='col-5 col-lg-2 col-md-6 col-sm-6 footer-col-4'>
                  <div className='widget widget_services border-0'>
                    <h5 className='m-b30 text-white'>Company</h5>
                    <ul>
                      <li>
                        <Link to={'/company'}>About </Link>
                      </li>
                      <li>
                        <Link to={'/careers'}>Careers </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-7 col-lg-2 col-md-6 col-sm-6 footer-col-4'>
                  <div className='widget widget_services border-0'>
                    <h5 className='m-b30 text-white'>Solutions</h5>
                    {/* <ul>
                      <li>
                        <Link to={'/services/web-development'}>
                          Web Development
                        </Link>
                      </li>
                      <li>
                        <Link to={'/services/mobile-development'}>
                          Mobile App Development
                        </Link>
                      </li>
                      <li>
                        <Link to={'/services/cloud-computing'}>
                          Cloud Services
                        </Link>
                      </li>
                    </ul> */}
                    <ul>
                      <li>
                        <Link to={'/services'}>Web Development</Link>
                      </li>
                      <li>
                        <Link to={'/services'}>Mobile App Development</Link>
                      </li>
                      <li>
                        <Link to={'/services'}>Cloud Services</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6 footer-col-4'>
                  <div className='widget widget_getintuch'>
                    <h5 className='m-b30 text-white '>Contact us</h5>
                    <ul>
                      <li>
                        <i className='ti-location-pin'></i>
                        <strong>address</strong> Plot No. 790-791, Sudna,
                        Medininagar (Daltonganj), Palamau, Jharkhand (822101),
                        IN
                      </li>
                      <li>
                        <i className='ti-mobile'></i>
                        <strong>phone</strong>+91-7739967505 (24/7 Support Line)
                      </li>
                      <li>
                        <i className='ti-email'></i>
                        <strong>email</strong>admin@zenuinebit.com
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6 footer-col-4 '>
                  <div className='widget'>
                    <h5 className='m-b30 text-white'>
                      Subscribe To Our Newsletter
                    </h5>
                    <p className='text-capitalize m-b20'>
                      Subscribe to our newsletter to get our latest products.
                    </p>
                    <div className='subscribe-form m-b20'>
                      <form
                        className='dzSubscribe'
                        action='script/mailchamp.php'
                        method='post'
                      >
                        <div className='dzSubscribeMsg'></div>
                        <div className='input-group'>
                          <input
                            name='dzEmail'
                            required='required'
                            className='form-control'
                            placeholder='Your Email Id'
                            type='email'
                          />
                          <span className='input-group-btn'>
                            <button
                              name='submit'
                              value='Submit'
                              type='submit'
                              className='btn btn-outline-primary'
                              style={{
                                height: '100%',
                                marginLeft: '10px',
                              }}
                            >
                              Subscribe
                            </button>
                          </span>
                        </div>
                      </form>
                    </div>
                    <ul className='list-inline m-a0'>
                      <li>
                        <Link
                          to={'#'}
                          className='site-button facebook circle mr-1'
                        >
                          <i className='fa fa-facebook'></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={'#'}
                          className='site-button google-plus circle mr-1'
                        >
                          <i className='fa fa-google-plus'></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={'#'}
                          className='site-button linkedin circle mr-1'
                        >
                          <i className='fa fa-linkedin'></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={'#'}
                          className='site-button instagram circle mr-1'
                        >
                          <i className='fa fa-instagram'></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={'#'}
                          className='site-button twitter circle mr-1'
                        >
                          <i className='fa fa-twitter'></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-bottom'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-6 text-left '>
                  {' '}
                  <span>
                    Copyright © 2024 zenuinebit Technologies pvt ltd
                  </span>{' '}
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 text-right '>
                  <div className='widget-link '>
                    <ul>
                      <li>
                        <Link to={'#'}> Help Desk</Link>
                      </li>
                      <li className='ml-1'>
                        <Link to={'#'}> Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer1;

import React, { Component } from 'react';
import PageTitle from './../../Layout/PageTitle';

//Images
import bnr1 from './../../../images/banner/bnr4.jpg';

class Company extends Component {
  render() {
    return (
      <>
        <div className='page-content bg-white'>
          <div
            className='dlab-bnr-inr overlay-primary'
            style={{ backgroundImage: 'url(' + bnr1 + ')' }}
          >
            <PageTitle motherMenu='About' activeMenu='Company' />
          </div>

          <div className='content-block'>
            <div className='section-full content-inner exp-services'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='section-head text-black text-center'>
                      <h2 className='text-gray-dark font-weight-500 m-b10'>
                        Company
                      </h2>
                      <p
                        className='font-weight-300'
                        style={{
                          fontSize: '25px',
                        }}
                      >
                        ZENUINEBIT Technologies Private Limited is a company
                        that specializes in designing, developing, and
                        delivering high-quality web, mobile, and desktop
                        applications. We understand how vital it is for our
                        clients to remain ahead of the curve in today's
                        fast-paced technological world. As such, we work closely
                        with them, providing innovative solutions that help them
                        bring their businesses and operations online. As an IT
                        consultancy, we offer expert advice, guidance, and
                        support to our clients, helping them make informed
                        decisions, and implement effective strategies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className='section-full content-inner'>
              <div className='container'>
                <div className='section-head text-black text-center'>
                  <h2 className='text-gray-dark m-b10'>Vision & Mission</h2>
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-6 col-md-12 col-sm-12 m-b30'>
                    <div className='icon-bx-wraper expertise  bx-style-1 p-a30 center'>
                      <h4 className='text-gray-dark m-b10'>Vision</h4>
                      <div className='icon-content'>
                        We believe that software can bring positive changes to
                        mankind
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-12 col-sm-12 m-b30'>
                    <div className='icon-bx-wraper expertise  bx-style-1 p-a30 center'>
                      <h4 className='text-gray-dark m-b10'>Mission</h4>
                      <div className='icon-content'>
                        To Accelerate the power of software services to mankind
                      </div>
                    </div>
                  </div>
                  {/* {titleBlog.map((data, index) => (
                    <div
                      className='col-lg-4 col-md-6 col-sm-6 m-b30'
                      key={index}
                    >
                      <div className='icon-bx-wraper expertise  bx-style-1 p-a30 center'>
                        <div className='icon-content'>
                          <h4 className='dlab-tilte text-uppercase'>
                            <Link to={'#'}>
                              {data.title1} <br />
                              {data.title2}
                            </Link>
                          </h4>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the.
                          </p>
                          <Link
                            to={'#'}
                            className='site-button radius-xl outline outline-2'
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Company;

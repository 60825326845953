import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Aboutus1 from './Pages/Aboutus/Aboutus1';
import Aboutus2 from './Pages/Aboutus/Aboutus2';
import BlogClassicGrid from './Pages/BlogClassic/BlogClassicGrid';
import BlogDetails from './Pages/BlogDetail/BlogDetails';
import Contact from './Pages/Contact';
import Error404 from './Pages/Error/Error404';
import Expertise from './Pages/Expertise';
import Faqs from './Pages/Faqs';
import OurProjects from './Pages/OurProjects';
import ProjectDetails from './Pages/ProjectDetails';
import ProjectManagement from './Pages/ProjectManagement';
import Service from './Pages/Services/Service';
import ServicesDetails from './Pages/Services/ServicesDetails';
import ShopColumns from './Pages/Shop/ShopColumns';
import ShopColumnsSidebar from './Pages/Shop/ShopColumnsSidebar';
import ShortAccordions from './Pages/ShortCode/ShortAccordions';
import ShortCounter from './Pages/ShortCode/ShortCounter';
import ShortForm from './Pages/ShortCode/ShortForm';
import ShortIconBox from './Pages/ShortCode/ShortIconBox';
import ShortPortfolio from './Pages/ShortCode/ShortPortfolio';
import ShortTabs from './Pages/ShortCode/ShortTabs';
import ShortTeam from './Pages/ShortCode/ShortTeam';
import ShortTestimonial from './Pages/ShortCode/ShortTestimonial';
import Team from './Pages/Team';

import ScrollToTop from './Element/ScrollToTop';
import Footer1 from './Layout/Footer1';
import PageTitle from './Layout/PageTitle';

import Header3 from './Layout/header3';
import { default as Careers } from './Pages/About/Careers';
import Company from './Pages/About/Company';
import HomePage from './Pages/Home';
import InterviewCSS from './Pages/Interview/Css';
import { default as Career } from './Pages/Jobs';
import JobDescription from './Pages/Jobs/JobDescription';
import LoginRegister from './Pages/Login-Register';
import B2BService from './Pages/Services/B2BService';
import CloudServices from './Pages/Services/CloudServices';
import CustomApplication from './Pages/Services/CustomApplication';
import DesktopDevelopment from './Pages/Services/DesktopDevelopment';
import Freelancing from './Pages/Services/Freelancing';
import MobileDevelopment from './Pages/Services/MobileDevelopment';
import WebDevelopment from './Pages/Services/WebDevelopment';
import WebHosting from './Pages/Services/WebHosting';

// const WithPageTitle = (OriginalComponent) => {
//   return () => {
//     return (
//       <div className="page-content bg-white">
//         <div
//           className="dlab-bnr-inr overlay-primary"
//           style={{ height: "125px", paddingBottom: "5px" }}
//         >
//           <PageTitle motherMenu="About Us 1" activeMenu="About Us 1" />
//         </div>
//         <OriginalComponent />
//       </div>
//     );
//   };
// };

const WithPageTitleComponent = ({ children }) => (
  <div className='page-content bg-white'>
    <div
      className='dlab-bnr-inr overlay-primary'
      style={{ height: '125px', paddingBottom: '5px' }}
    >
      <PageTitle motherMenu='About Us 1' activeMenu='About Us 1' />
    </div>
    {children}
  </div>
);

const Markup = () => {
  const isUserVerified = true;

  return isUserVerified ? (
    <BrowserRouter>
      <div className='page-wraper'>
        <Header3 />
        <Routes>
          <Route path='/' exact element={<HomePage />} />
          <Route path='company' exact element={<Company />} />
          <Route path='careers' exact element={<Careers />} />
          <Route path='jobs/india' exact element={<Career />} />
          <Route
            path='jobs/india/job-description/'
            exact
            element={<JobDescription />}
          />
          <Route
            path='about-1'
            exact
            element={
              <WithPageTitleComponent>
                <Aboutus1 />
              </WithPageTitleComponent>
            }
          />
          <Route
            path='about-2'
            exact
            element={
              <WithPageTitleComponent>
                <Aboutus2 />
              </WithPageTitleComponent>
            }
          />
          <Route
            path='tutorial/html/about-1'
            exact
            element={
              <WithPageTitleComponent>
                <Aboutus1 />
              </WithPageTitleComponent>
            }
          />
          <Route
            path='tutorial/html/about-2'
            exact
            element={
              <WithPageTitleComponent>
                <Aboutus2 />
              </WithPageTitleComponent>
            }
          />
          <Route
            path='tutorial/css/about-1'
            exact
            element={
              <WithPageTitleComponent>
                <Aboutus1 />
              </WithPageTitleComponent>
            }
          />
          <Route
            path='tutorial/css/about-2'
            exact
            element={
              <WithPageTitleComponent>
                <Aboutus2 />
              </WithPageTitleComponent>
            }
          />
          <Route
            path='exercise/html/about-1'
            exact
            element={
              <WithPageTitleComponent>
                <Aboutus1 />
              </WithPageTitleComponent>
            }
          />
          <Route
            path='exercise/html/about-2'
            exact
            element={
              <WithPageTitleComponent>
                <Aboutus2 />
              </WithPageTitleComponent>
            }
          />
          <Route
            path='exercise/css/about-1'
            exact
            element={
              <WithPageTitleComponent>
                <Aboutus1 />
              </WithPageTitleComponent>
            }
          />
          <Route
            path='exercise/css/about-2'
            exact
            element={
              <WithPageTitleComponent>
                <Aboutus2 />
              </WithPageTitleComponent>
            }
          />
          <Route
            path='interivew/css'
            exact
            element={
              <WithPageTitleComponent>
                <InterviewCSS />
              </WithPageTitleComponent>
            }
          />
          <Route path='error-404' exact element={Error404} />

          <Route path='expertise' exact element={<Expertise />} />
          <Route path='project-management' exact element={ProjectManagement} />
          <Route path='our-projects' exact element={OurProjects} />
          <Route path='project-details' exact element={ProjectDetails} />
          <Route path='team' exact element={Team} />
          <Route path='faqs' exact element={Faqs} />
          {/* <Route path="/login" exact element={Login} />
              <Route path="/register" exact element={Register} /> */}
          <Route path='/login' exact element={<LoginRegister />} />
          <Route path='contact' exact element={<Contact />} />
          <Route path='services' exact element={<Service />} />
          <Route
            path='services/web-development'
            exact
            element={<WebDevelopment />}
          />
          <Route path='services/web-hosting' exact element={<WebHosting />} />
          <Route
            path='services/mobile-development'
            exact
            element={<MobileDevelopment />}
          />
          <Route
            path='services/desktop-development'
            exact
            element={<DesktopDevelopment />}
          />
          <Route
            path='services/cloud-computing'
            exact
            element={<CloudServices />}
          />
          <Route
            path='services/custom-application'
            exact
            element={<CustomApplication />}
          />
          <Route path='services/b2b' exact element={<B2BService />} />
          <Route path='services/freelancing' exact element={<Freelancing />} />
          <Route path='services-details' exact element={<ServicesDetails />} />
          <Route path='blogs' exact element={<BlogClassicGrid />} />
          <Route path='blog-details' exact element={<BlogDetails />} />
          {/* <Route path='blog-standard' exact element={BlogStandard} />
          <Route
            path='blog-standard-left-sidebar'
            exact
            element={BlogStandardLeftSidebar}
          />
          <Route
            path='blog-standard-right-sidebar'
            exact
            element={
              <WithPageTitleComponent>
                <BlogStandardRightSidebar />
              </WithPageTitleComponent>
            }
          />
          <Route path='blog-classic-grid' exact element={BlogClassicGrid} />
          <Route
            path='blog-classic-left-sidebar'
            exact
            element={BlogClassicLeftSidebar}
          />
          <Route
            path='blog-classic-right-sidebar'
            exact
            element={BlogClassicRightSidebar}
          />
          <Route
            path='blog-list-left-sidebar'
            exact
            element={BlogListLeftSidebar}
          />
          <Route
            path='blog-list-right-sidebar'
            exact
            element={BlogListRightSidebar}
          />
          
          <Route
            path='portfolio-full-width'
            exact
            element={ProtfolioFullWidth}
          /> */}
          <Route path='shop-columns' exact element={ShopColumns} />
          <Route
            path='shop-columns-sidebar'
            exact
            element={ShopColumnsSidebar}
          />
          <Route path='short-icon-box' exact element={ShortIconBox} />
          <Route path='short-counter' exact element={ShortCounter} />
          <Route path='short-portfolio' exact element={ShortPortfolio} />
          <Route
            path='short-tabs'
            exact
            element={
              <WithPageTitleComponent>
                <ShortTabs />
              </WithPageTitleComponent>
            }
          />
          <Route path='short-team' exact element={ShortTeam} />
          <Route path='short-testimonial' exact element={ShortTestimonial} />
          <Route path='short-form' exact element={ShortForm} />
          <Route path='short-accordions' exact element={ShortAccordions} />
          <Route path='*' element={<Error404 />} />
        </Routes>
        <Footer1 />
      </div>
      <ScrollToTop />
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <LoginRegister />
    </BrowserRouter>
  );
};

export default Markup;

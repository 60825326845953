import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HeaderMenu extends Component {
  render() {
    return (
      <>
        <ul className='nav navbar-nav'>
          <li>
            <Link to={'#'}>
              About <i className='fa fa-chevron-down'></i>
            </Link>
            <ul className='sub-menu'>
              <li>
                <Link to={'/company'} className='dez-page'>
                  Company
                </Link>
              </li>
              <li>
                <Link to={'/careers'} className='dez-page'>
                  Career
                </Link>
              </li>
            </ul>
          </li>
          {/* <li>
            <Link to={'#'}>
              Services <i className='fa fa-chevron-down'></i>
            </Link>
            <ul className='sub-menu'>
              <li>
                <Link to={'./services'} className='dez-page'>
                  Services
                </Link>
              </li>
              <li>
                <Link to={'./services/web-development'} className='dez-page'>
                  Web Development
                </Link>
              </li>
              <li>
                <Link to={'./services/web-hosting'} className='dez-page'>
                  Web Hosting
                </Link>
              </li>
              <li>
                <Link to={'./services/mobile-development'} className='dez-page'>
                  Mobile Application Development
                </Link>
              </li>
              <li>
                <Link
                  to={'./services/desktop-development'}
                  className='dez-page'
                >
                  Desktop Application Development
                </Link>
              </li>
              <li>
                <Link to={'./services/cloud-computing'} className='dez-page'>
                  Cloud Computing Services
                </Link>
              </li>
              <li>
                <Link to={'./services/custom-application'} className='dez-page'>
                  Custom Application
                </Link>
              </li>
              <li>
                <Link to={'./services/freelancing'} className='dez-page'>
                  Freelancing
                </Link>
              </li>
              <li>
                <Link to={'./services/b2b'} className='dez-page'>
                  B2B Development Services
                </Link>
              </li>
            </ul>
          </li> */}
          {/* <li>
            <Link to={'/blogs'}>Blogs</Link>
          </li> */}
          <li>
            <Link to={'./services'} className='dez-page'>
              Services
            </Link>
          </li>
          <li>
            <Link to={'/login'}>Login</Link>
          </li>
          {/* <li>
            <Link to={'#'}>
              Blog <i className='fa fa-chevron-down'></i>
            </Link>
            <ul className='sub-menu'>
              <li>
                <Link to={'#'}>
                  Blog Standard <i className='fa fa-angle-right'></i>
                </Link>
                <ul className='sub-menu left'>
                  <li>
                    <Link
                      to={'/blog-standard-right-sidebar'}
                      className='dez-page'
                    >
                      Standard Right Sidebar
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={'#'}>
                  Blog Classic <i className='fa fa-angle-right'></i>
                </Link>
                <ul className='sub-menu left'>
                  <li>
                    <Link
                      to={'./blog-classic-right-sidebar'}
                      className='dez-page'
                    >
                      Classic Right Sidebar
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={'#'}>
                  Blog List <i className='fa fa-angle-right'></i>
                </Link>
                <ul className='sub-menu left'>
                  <li>
                    <Link to={'./blog-list-right-sidebar'} className='dez-page'>
                      List Right Sidebar
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={'./blog-details'} className='dez-page'>
                  Blog Details
                </Link>
              </li>
            </ul>
          </li> */}
          {/* <li>
            <Link to={"#"}>
              Tutorials <i className="fa fa-chevron-down"></i>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to={"#"}>
                  HTML <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"/tutorial/html/about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"/tutorial/html/about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"#"}>
                  CSS <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"/tutorial/css/about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"/tutorial/css/about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"#"}>
                  Bootstrap <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"./about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"./about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"#"}>
                  JavaScript <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"./about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"./about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"#"}>
                  JQuery <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"./about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"./about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"#"}>
                  React <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"./about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"./about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <Link to={"#"}>
              Exercises <i className="fa fa-chevron-down"></i>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to={"#"}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>HTML</div>
                    <div style={{ paddingRight: "10px" }}>2</div>
                  </div>
                  <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"/exercise/html/about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"/exercise/html/about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"#"}>
                  CSS <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"/exercise/css/about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"/exercise/css/about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"#"}>
                  Bootstrap <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"./about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"./about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"#"}>
                  JavaScript <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"./about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"./about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"#"}>
                  JQuery <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"./about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"./about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"#"}>
                  React <i className="fa fa-angle-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={"./about-1"} className="dez-page">
                      About Us 1
                    </Link>
                  </li>
                  <li>
                    <Link to={"./about-2"} className="dez-page">
                      About Us 2
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li> */}

          {/* <li>
            <Link to={'#'}>
              Interview <i className='fa fa-chevron-down'></i>
            </Link>
            <ul className='sub-menu left'>
              <li>
                <Link to={'/interivew/html'} className='dez-page'>
                  HTML
                </Link>
              </li>
              <li>
                <Link to={'/interivew/css'} className='dez-page'>
                  CSS
                </Link>
              </li>
              <li>
                <Link to={'/interivew/javascript'} className='dez-page'>
                  JavaScript
                </Link>
              </li>
            </ul>
          </li> */}
          {/* <li>
            <Link to={"#"}>
              Element <i className="fa fa-chevron-down"></i>{" "}
            </Link>
            <ul className="sub-menu left">
              <li>
                <Link to={"./short-icon-box"} className="dez-page">
                  Icon Box
                </Link>
              </li>
              <li>
                <Link to={"./short-counter"} className="dez-page">
                  Counter
                </Link>
              </li>
              <li>
                <Link to={"./short-portfolio"} className="dez-page">
                  Portfolio
                </Link>
              </li>
              <li>
                <Link to={"./short-tabs"} className="dez-page">
                  Tabs
                </Link>
              </li>
              <li>
                <Link to={"./short-team"} className="dez-page">
                  Team
                </Link>
              </li>
              <li>
                <Link to={"./short-testimonial"} className="dez-page">
                  Testimonial
                </Link>
              </li>
              <li>
                <Link to={"./short-form"} className="dez-page">
                  Form
                </Link>
              </li>
              <li>
                <Link to={"./short-accordions"} className="dez-page">
                  Accordions
                </Link>
              </li>
            </ul>
          </li> */}
        </ul>
      </>
    );
  }
}
export default HeaderMenu;

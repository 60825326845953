import React, { Component } from 'react';
import FormStyle from '../../Element/FormStyle';
import HomeOwlSlider from '../../Element/HomeOwlSlider';
import ImgCarouselContent from '../../Element/ImgCarouselContent';

//Images
import pic4Replacement from '../../../images/about/pic4-replacement.jpg';
import ExploreCarousel from '../../Element/ExploreCarousel';

import { default as bgimg3 } from '../../../images/background/bg1.jpg';
import bg3 from '../../../images/background/bg3.png';

class HomePage extends Component {
  render() {
    return (
      <div className='page-wraper'>
        <div className='page-content bg-white'>
          {/*  Slider Banner */}
          <div className='owl-slider-banner main-slider'>
            <HomeOwlSlider />
            {/*  Service   */}
            <div className='service-box-slide'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <ImgCarouselContent />
                  </div>
                </div>
              </div>
            </div>
            {/*  Service End  */}
          </div>
          {/*  Slider Banner */}
          <div className='content-block'>
            {/* <!-- About Us --> */}
            <div className='section-full bg-white content-inner-1 about-us'>
              <div className='container'>
                <div className='row '>
                  <div className='col-lg-7 col-md-8'>
                    <div className='abuot-box row'>
                      <div className='col-lg-4'>
                        <h2 className='box-title m-tb0'>
                          About Us<span className='bg-primary'></span>
                        </h2>
                        <h4 className='text-gray-dark'>
                          We create unique experiences
                        </h4>
                      </div>
                      <div className='col-lg-8'>
                        <p>
                          ZENUINEBIT Technologies Private Limited is a company
                          that specializes in designing, developing, and
                          delivering high-quality web, mobile, and desktop
                          applications. We understand how vital it is for our
                          clients to remain ahead of the curve in today's
                          fast-paced technological world. As such, we work
                          closely with them, providing innovative solutions that
                          help them bring their businesses and operations
                          online. As an IT consultancy, we offer expert advice,
                          guidance, and support to our clients, helping them
                          make informed decisions, and implement effective
                          strategies.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-5 col-md-4 about-img'>
                    <img src={pic4Replacement} data-tilt alt='' />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- About Us End --> */}
            {/* <!-- Services --> */}
            {/* <div className='section-full content-inner-2'>
              <div className='container'>
                <div className='section-head text-black text-center'>
                  <h3 className='text-gray-dark m-b10'>Services</h3>
                  <h4 className='box-title m-tb0'>
                    Services that we provide<span className='bg-primary'></span>
                  </h4>
                  <p>
                    We are strived to provide the world class services to our
                    customers and clients and bring a lot of differences
                  </p>
                </div>
              </div>
              <div className='development-box'>
                <div className='container'>
                  <Services />
                </div>
              </div>
            </div> */}
            {/* <!-- Services --> */}
            {/* <!-- Why Chose Us --> */}
            {/* <div
              className='section-full content-inner-1 overlay-primary choseus-tabs  bg-img-fix'
              style={{ backgroundImage: 'url(' + bgimg + ')' }}
            >
              <div className='container'>
                <div className='section-head text-white text-center'>
                  <h2 className='box-title m-tb0'>
                    Why Choose Us<span className='bg-primary'></span>
                  </h2>
                  <p>
                    At ZENUINEBIT, we are proud to be unique in our approach
                    todesigning and developing applications. As a young startup,
                    we havea passion for innovation and utilizing the latest
                    technologies tobuild cutting-edge software solutions that
                    make a real difference.One of the things that set us apart
                    from other softwaredevelopment firms is our focus on
                    state-of-the-art technologies. Werecognize that to create
                    truly innovative and competitive products,we must stay at
                    the forefront of the latest technologies. Therefore,we use
                    the latest programming languages, frameworks, and toolsto
                    create custom software solutions
                  </p>
                </div>
              </div>
              <HomeTab />
              <div className='choses-info text-white'>
                <Counter />
              </div>
            </div> */}
            {/* <!-- Why Chose Us End --> */}
            {/* <!-- Our Portfolio --> */}
            {/* <div className='section-full content-inner-1 mfp-gallery'>
              <div className='container-fluid'>
                <div className='section-head text-center'>
                  <h4 className='text-gray-dark m-b10'>Our Portfolio</h4>
                  <h2 className='box-title m-tb0'>
                    We work to innovate & are proud
                    <br /> of what we've created
                    <span className='bg-primary'></span>
                  </h2>
                  <h5>
                    YOU’LL LOVE TO SEE SOME OF OUR BEST PROJECTS. TAKE A LOOK!
                  </h5>
                </div>
                <PortfolioCarousel />
              </div>
            </div> */}
            {/* <!-- Our Portfolio END --> */}

            {/* <!-- Map And Form --> */}
            <div className='section-full bg-white content-inner-2 '>
              <div className='container'>
                <div className='row equal-wraper3'>
                  <div className='col-lg-5 equal-col dis-tbl m-b30'>
                    <div className='dis-tbl-cell  m-b30'>
                      <h2 className='m-t0 m-b10'>
                        Build your applications with extra functionality
                      </h2>
                      <ul className='list-angle-right'>
                        <li>Fully focused on accessibility</li>
                        <li>Based on modern design concept</li>
                        <li>
                          Impressive and attractive design with graceful
                          features
                        </li>
                        <li>More creative with smoothness and flexibility </li>
                        <li>Unlimited power and customization possibilities</li>
                        <li>Search Engine Optimization (SEO) friendly</li>
                      </ul>
                      {/* <Link
                        to={'#'}
                        className='site-button black outline outline-2'
                      >
                        Read More <i className='ti-arrow-right m-l10'></i>
                      </Link> */}
                    </div>
                  </div>
                  <div className='col-lg-7 equal-col'>
                    <img src={pic4Replacement} alt='' />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Map And Form END --> */}

            {/* <!-- Clients Words --> */}
            {/*<div
              className='section-full overlay-primary-middle content-inner bg-img-fix'
              style={{ backgroundImage: 'url(' + bg2 + ')' }}
            >
              <div className='container'>
                <div className='max-w600 m-auto text-center m-b30 text-white'>
                  <h6 className='m-t0'>What people say</h6>
                  <h2 className='m-t0'>Clients Words</h2>
                </div>
                <div className='section-content m-b30'>
                  <ClientWords />
                </div>
              </div>
            </div>*/}
            {/* <!-- Clients Words END --> */}

            {/* <!-- Explore our projects --> */}
            <div
              className='section-full bg-blue-light content-inner explore-projects'
              style={{ backgroundImage: 'url(' + bg3 + ')' }}
            >
              <div className='container'>
                <div className='section-content'>
                  <div className='row'>
                    <div className='col-md-12 col-lg-12 section-head text-center'>
                      <h2 className='m-b0 font-40'>
                        <span className='font-weight-400'>Explore</span>{' '}
                        Projects
                      </h2>
                      <p className='m-b0'>
                        We have listed down some of our best projects that we
                        have developed in the past and there are some more
                        projects that are in the progress.
                      </p>
                    </div>
                  </div>
                  {/* <!-- blog post Carousel with no margin --> */}
                  <div className=''>
                    <ExploreCarousel />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Explore our projects End --> */}

            {/* <!-- Latest Blogs --> */}
            {/* <div className='section-full bg-white content-inner'>
              <div className='container'>
                <div className='max-w600 m-auto text-center m-b30'>
                  <h2 className='m-t0'>Latest Blogs</h2>
                </div>
                <LatestBlogSlider />
              </div>
            </div>*/}
            {/* <!-- Latest Blogs End --> */}

            {/* <!-- Get in touch --> */}
            <div
              className='section-full overlay-primary-dark bg-img-fix'
              style={{ backgroundImage: 'url(' + bgimg3 + ')' }}
            >
              <FormStyle />
            </div>
            {/* <!-- Get in touch --> */}
          </div>
        </div>
      </div>
    );
  }
}
export default HomePage;

import React, { Component } from "react";
import PageTitle from "./../../Layout/PageTitle";
import HomeTab from "./../../Element/HomeTab";
import TabStyle1 from "./TabStyle1";

import bnr1 from "./../../../images/background/bg1.jpg";
import bnr2 from "./../../../images/banner/bnr2.jpg";

class ShortTabs extends Component {
  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            {/* <!-- Testimonials --> */}
            <div style={{ height: "10px" }}></div>
            {/* <!-- Why Chose Us --> */}
            <div
              className="section-full content-inner-2 overlay-primary choseus-tabs  bg-img-fix"
              style={{ backgroundImage: "url(" + bnr1 + ")" }}
            >
              <div
                className="sort-title-bx text-white"
                data-name="Element Style 2"
              >
                Element Style 2 <i className="fa fa-angle-double-right"></i>
              </div>
              <div className="container">
                <div className="section-head text-white text-center">
                  <h2 className="box-title m-tb0">
                    Why Chose Us<span className="bg-primary"></span>
                  </h2>
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over
                  </p>
                </div>
              </div>
              <HomeTab />
            </div>
            {/* <!-- Why Chose Us End --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>
      </>
    );
  }
}
export default ShortTabs;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//Images

const styles = {
  jobStyle: {
    padding: '10px 20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  loadMoreButton: {
    width: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
class Career extends Component {
  render() {
    return (
      <>
        <div className='page-content bg-white'>
          <div style={{ height: '100px' }}></div>
          <div className='content-block'>
            <div
              className='section-full'
              style={{
                marginTop: '10px',
                marginBottom: '150px',
              }}
            >
              <div className='container'>
                <div className='section-head text-black text-center'>
                  <h2 className='box-title m-tb0'>
                    Discover Job Opportunities
                  </h2>
                  <h6>Open Positions (4)</h6>
                </div>
              </div>
              <div className='container'>
                <div className='row '>
                  <div className='col-lg-4 col-md-4 col-sm-4'>
                    <input
                      class='form-control'
                      placeholder='Enter job titles, skill, keyword'
                    />
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-4'>
                    <select class='form-select'>
                      <option selected value='India'>
                        India
                      </option>
                      <option value='USA'>USA</option>
                    </select>
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-4'>
                    <button
                      type='button'
                      class='btn btn-outline-success'
                      style={{
                        width: '100%',
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div
                  className='row'
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <div className='col-lg-12 col-md-12 col-sm-12'>
                    <ol class='list-group list-group-numbered'>
                      <li class='list-group-item d-flex justify-content-between align-items-start'>
                        <div class='ms-2 me-auto'>
                          <div class='fw-bold'>
                            Lead I - Software Engineering{' '}
                            <div class='badge bg-primary'>1</div>
                          </div>
                          Pune, Maharashtra
                        </div>
                        <Link to='/jobs/india/job-description'>
                          <div style={styles.jobStyle}>
                            <i class='bi bi-arrow-right'></i>
                          </div>
                        </Link>
                      </li>
                      <li class='list-group-item d-flex justify-content-between align-items-start'>
                        <div class='ms-2 me-auto'>
                          <div class='fw-bold'>
                            Frontend Engineer {''}
                            <div class='badge bg-primary'>1</div>
                          </div>
                          Pune, Maharashtra
                        </div>
                        <Link to='/jobs/india/job-description'>
                          <div style={styles.jobStyle}>
                            <i class='bi bi-arrow-right'></i>
                          </div>
                        </Link>
                      </li>
                      <li class='list-group-item d-flex justify-content-between align-items-start'>
                        <div class='ms-2 me-auto'>
                          <div class='fw-bold'>
                            UI/UX Engineer <div class='badge bg-primary'>2</div>
                          </div>
                          Pune, Maharashtra
                        </div>
                        <Link to='/jobs/india/job-description'>
                          <div style={styles.jobStyle}>
                            <i class='bi bi-arrow-right'></i>
                          </div>
                        </Link>
                      </li>
                    </ol>
                  </div>
                </div>
                <div
                  className='row'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <button
                    type='button'
                    class='btn btn-outline-success'
                    style={styles.loadMoreButton}
                  >
                    Load More
                    <i
                      class='bi bi-plus'
                      style={{
                        fontSize: '20px',
                      }}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Career;

import React, { Component } from 'react';

const styles = {
  jobStyle: {
    padding: '10px 20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  loadMoreButton: {
    width: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

class JobDescription extends Component {
  render() {
    return (
      <>
        <div className='page-content bg-white'>
          <div style={{ height: '100px' }}></div>
          <div className='content-block'>
            <div className='section-full content-inner'>
              <div className='container'>
                <div className='section-head text-black text-center'>
                  <h2 className='box-title m-tb0'>
                    Lead I - Software Engineering
                  </h2>
                  <h6>Pune, Maharashtra, India</h6>
                  <div
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      marginTop: '50px',
                      marginBottom: '10px',
                    }}
                  >
                    <h4
                      style={{
                        padding: '10px',
                      }}
                    >
                      Job Info
                    </h4>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <table
                        style={{
                          width: '50%',
                          border: '1px solid #f7f3f3',
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>Job Identification</td>
                            <td>:</td>
                            <td>1001</td>
                          </tr>
                          <tr>
                            <td>Job Category</td>
                            <td>:</td>
                            <td>Software Engineering</td>
                          </tr>
                          <tr>
                            <td>Posting Date</td>
                            <td>:</td>
                            <td>10/21/2024, 01:34 PM</td>
                          </tr>
                          <tr>
                            <td>Apply Before</td>
                            <td>:</td>
                            <td>11/30/2024, 01:34 PM</td>
                          </tr>
                          <tr>
                            <td>Job Schedules</td>
                            <td>:</td>
                            <td>Full time</td>
                          </tr>
                          <tr>
                            <td>Locations</td>
                            <td>:</td>
                            <td> Pune, India</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <h4>JOB DESCRIPTION</h4>
                </div>
                <div className='row'>
                  <h6>Role Proficiency:</h6>
                  <p>
                    Act creatively to develop applications and select
                    appropriate technical options optimizing application
                    development maintenance and performance by employing design
                    patterns and reusing proven solutions account for others'
                    developmental activities
                  </p>

                  <div>
                    <h6>Outcomes:</h6>
                    <ol
                      style={{
                        marginLeft: '20px',
                      }}
                    >
                      <li>
                        Interpret the application/feature/component design to
                        develop the same in accordance with specifications.
                      </li>
                      <li>
                        Code debug test document and communicate
                        product/component/feature development stages.
                      </li>
                      <li>
                        Validate results with user representatives; integrates
                        and commissions the overall solution
                      </li>
                      <li>
                        Select appropriate technical options for development
                        such as reusing improving or reconfiguration of existing
                        components or creating own solutions
                      </li>
                      <li>Optimises efficiency cost and quality.</li>
                      <li>Influence and improve customer satisfaction</li>
                      <li>
                        Set FAST goals for self/team; provide feedback to FAST
                        goals of team members
                      </li>
                    </ol>
                  </div>
                  <div>
                    <h6>Measures of Outcomes:</h6>
                    <ol
                      style={{
                        marginLeft: '20px',
                      }}
                    >
                      <li>
                        Adherence to engineering process and standards (coding
                        standards)
                      </li>
                      <li>Adherence to project schedule / timelines</li>
                      <li>
                        Number of technical issues uncovered during the
                        execution of the project
                      </li>
                      <li>Number of defects in the code</li>
                      <li>Number of defects post delivery</li>
                      <li>Number of non compliance issues</li>
                      <li>
                        On time completion of mandatory compliance trainings
                      </li>
                    </ol>
                  </div>
                  <div>
                    <h6>Outputs Expected:</h6>
                    <div
                      style={{
                        marginLeft: '20px',
                        color: 'black',
                      }}
                    >
                      Code:
                    </div>
                    <ol
                      style={{
                        marginLeft: '50px',
                      }}
                    >
                      <li>Code as per design</li>
                      <li>Follow coding standards templates and checklists</li>
                      <li>Review code – for team and peers</li>
                    </ol>
                    <div
                      style={{
                        marginLeft: '20px',
                        color: 'black',
                      }}
                    >
                      Documentation:
                    </div>
                    <ol
                      style={{
                        marginLeft: '50px',
                      }}
                    >
                      <li>
                        Create/review templates checklists guidelines standards
                        for design/process/development
                      </li>
                      <li>
                        Create/review deliverable documents. Design
                        documentation r and requirements test cases/results
                      </li>
                    </ol>
                    <div
                      style={{
                        marginLeft: '20px',
                        color: 'black',
                      }}
                    >
                      Configure:
                    </div>
                    <ol
                      style={{
                        marginLeft: '50px',
                      }}
                    >
                      <li>Define and govern configuration management plan</li>
                      <li>Ensure compliance from the team</li>
                    </ol>
                    <div
                      style={{
                        marginLeft: '20px',
                        color: 'black',
                      }}
                    >
                      Test:
                    </div>
                    <ol
                      style={{
                        marginLeft: '50px',
                      }}
                    >
                      <li>
                        Review and create unit test cases scenarios and
                        execution
                      </li>
                      <li>Review test plan created by testing team</li>
                      <li>Provide clarifications to the testing team</li>
                    </ol>
                    <div
                      style={{
                        marginLeft: '20px',
                        color: 'black',
                      }}
                    >
                      Domain relevance:
                    </div>
                    <ol
                      style={{
                        marginLeft: '50px',
                      }}
                    >
                      <li>
                        Advise Software Developers on design and development of
                        features and components with a deep understanding of the
                        business problem being addressed for the client.
                      </li>
                      <li>
                        Learn more about the customer domain identifying
                        opportunities to provide valuable addition to customers
                      </li>
                      <li>Complete relevant domain certifications</li>
                    </ol>
                    <div>
                      <h6>Additional Comments:</h6>
                    </div>
                    <div
                      style={{
                        marginLeft: '20px',
                      }}
                    >
                      Mandatory Skill - React Js and Sql along with Unit Testing
                      React JS, strong in SQL, awareness in CICD, strong in
                      object oriented design , excellent hands-on skills React
                      JS, sql, cicd, oneops& kafka will be advantage Mandatory
                      dot net skill set and experience includes Dependency
                      injection, IOC containers, unit of work, structure map,
                      General C# library questions, .net core applications
                      specifically windows services, .net core web api.
                      Experience with CICD/DevOps deployment pipelines, managing
                      and maintaining them Good to have knowledge and experience
                      with Microsoft Azure cloud and storage blob containers.
                      Will be an advantage to have knowledge on KAFKA APIs
                      and/or Splunk logging/JDA SPACE modules. Proactive team
                      player with a *can-do* attitude
                    </div>
                  </div>
                </div>
                <div
                  className='row'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '20px',
                  }}
                >
                  <button
                    type='button'
                    class='btn btn-outline-primary'
                    style={styles.loadMoreButton}
                  >
                    Apply Now
                    <i
                      class='bi bi-plus'
                      style={{
                        fontSize: '20px',
                      }}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default JobDescription;

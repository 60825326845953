import classnames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane } from 'reactstrap';

const LoginRegister = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <div className='page-wraper'>
        <div
          className='page-content bg-white'
          style={{
            padding: '20px',
          }}
        >
          {/* <!-- contact area --> */}
          <div className='section-full content-inner shop-account'>
            {/* <!-- Product --> */}
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='dlab-tabs product-description tabs-site-button'>
                    <ul
                      className='nav nav-tabs'
                      style={{ justifyContent: 'center' }}
                    >
                      <li>
                        <Link
                          to={'#'}
                          style={{ width: '250px' }}
                          className={classnames({ active: activeTab === '1' })}
                          onClick={() => {
                            toggle('1');
                          }}
                        >
                          <i className='fa fa-globe'></i> Login
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={'#'}
                          style={{ width: '250px' }}
                          className={classnames({ active: activeTab === '2' })}
                          onClick={() => {
                            toggle('2');
                          }}
                        >
                          <i className='fa fa-photo' />
                          Register
                        </Link>
                      </li>
                    </ul>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId='1'>
                        {/* <div className='row'>
                          <div className='col-md-12 text-center'>
                            <h2 className='font-weight-700 m-t0 m-b30'>
                              Login Your Account
                            </h2>
                          </div>
                        </div> */}
                        <div>
                          <div className='max-w500 m-auto m-b30'>
                            <div className='p-a30 border-1 seth'>
                              <div className='tab-content nav'>
                                <form
                                  id='login'
                                  className='tab-pane active col-12 p-a0 '
                                >
                                  <h4 className='font-weight-700'>LOGIN</h4>
                                  <p className='font-weight-600'>
                                    If you have an account with us, please log
                                    in.
                                  </p>
                                  <div className='form-group'>
                                    <label className='font-weight-700'>
                                      E-MAIL *
                                    </label>
                                    <input
                                      name='dzName'
                                      required=''
                                      className='form-control'
                                      placeholder='Your Email Id'
                                      type='email'
                                    />
                                  </div>
                                  <div className='form-group'>
                                    <label className='font-weight-700'>
                                      PASSWORD *
                                    </label>
                                    <input
                                      name='dzName'
                                      required=''
                                      className='form-control '
                                      placeholder='Type Password'
                                      type='password'
                                    />
                                  </div>
                                  <div
                                    className='text-left'
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <button
                                      type='button'
                                      className='btn btn-outline-primary'
                                    >
                                      Login
                                    </button>
                                    <button
                                      data-toggle='tab'
                                      href='#forgot-password'
                                      className='btn btn-outline-primary'
                                    >
                                      <i className='fa fa-unlock-alt' /> Forgot
                                      Password
                                    </button>
                                  </div>
                                </form>
                                <form
                                  id='forgot-password'
                                  className='tab-pane fade  col-12 p-a0'
                                >
                                  <h4 className='font-weight-700'>
                                    FORGET PASSWORD ?
                                  </h4>
                                  <p className='font-weight-600'>
                                    We will send you an email to reset your
                                    password.
                                  </p>
                                  <div className='form-group'>
                                    <label className='font-weight-700'>
                                      E-MAIL *
                                    </label>
                                    <input
                                      name='dzName'
                                      required=''
                                      className='form-control'
                                      placeholder='Your Email Id'
                                      type='email'
                                    />
                                  </div>
                                  <div className='text-left'>
                                    <a
                                      className='site-button outline gray button-lg radius-no'
                                      data-toggle='tab'
                                      href='#login'
                                    >
                                      Back
                                    </a>
                                    <button className='site-button pull-right button-lg radius-no'>
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId='2'>
                        {/* <div className='row'>
                          <div className='col-md-12 text-center'>
                            <h2 className='font-weight-700 m-t0 m-b30'>
                              Create An Account
                            </h2>
                          </div>
                        </div> */}
                        <div className='row'>
                          <div className='col-md-12 m-b30'>
                            <div className='p-a30 border-1  max-w500 m-auto'>
                              <div className='tab-content'>
                                <form id='login' className='tab-pane active'>
                                  <h4 className='font-weight-700'>
                                    PERSONAL INFORMATION
                                  </h4>
                                  <p className='font-weight-600'>
                                    If you have an account with us, please log
                                    in.
                                  </p>
                                  <div className='form-group'>
                                    <label className='font-weight-700'>
                                      First Name *
                                    </label>
                                    <input
                                      name='dzName'
                                      required=''
                                      className='form-control'
                                      placeholder='First Name'
                                      type='text'
                                    />
                                  </div>
                                  <div className='form-group'>
                                    <label className='font-weight-700'>
                                      Last Name *
                                    </label>
                                    <input
                                      name='dzName'
                                      required=''
                                      className='form-control'
                                      placeholder='Last Name'
                                      type='text'
                                    />
                                  </div>
                                  <div className='form-group'>
                                    <label className='font-weight-700'>
                                      E-MAIL *
                                    </label>
                                    <input
                                      name='dzName'
                                      required=''
                                      className='form-control'
                                      placeholder='Your Email Id'
                                      type='email'
                                    />
                                  </div>
                                  <div className='form-group'>
                                    <label className='font-weight-700'>
                                      PASSWORD *
                                    </label>
                                    <input
                                      name='dzName'
                                      required=''
                                      className='form-control '
                                      placeholder='Type Password'
                                      type='password'
                                    />
                                  </div>
                                  <div className='text-left'>
                                    <button className='btn btn-outline-primary w-100'>
                                      Create
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Product END --> */}
          </div>
          {/* <-- Icon Blog and Order detail--> */}
          {/* <Footer5 /> */}
          {/* <-- Icon Blog and Order detail End--> */}

          {/* <!-- contact area  END --> */}
        </div>
      </div>
    </>
  );
};

export default LoginRegister;
